/**
 * Generates a pax configuration string based on room data.
 *
 * @param {Object} rooms An object representing room data, where keys are room numbers and values are room objects.
 *  Example:
 *  ```
 *  {
 *    "0": {
 *      "adults_count": 2,
 *      "children_count": 1,
 *      "ages": [5]
 *    },
 *    "1": {
 *      "adults_count": 1,
 *      "children_count": 2,
 *      "ages": [3, 10]
 *    }
 *  }
 *  ```
 * @param {number} rooms.adults_count The number of adults in the room.
 * @param {number} rooms.children_count The number of children in the room.
 * @param {number[]} rooms.ages An array of children's ages.
 * @returns {string} The pax configuration string in the format "adultspchildren-ages" separated by underscores for each room.
 */
const generatePaxConfig = rooms => {
  return Object.values(rooms)
    .map(room => {
      const adults = room.adults_count
      const children = room.children_count
      const ages = room.ages && Array.isArray(room.ages) ? room.ages.join('.') : ''

      return `${adults}p${children}${ages ? `-${ages}` : ''}`
    })
    .join('_')
}

export default generatePaxConfig
