import { loadAllBlocksAssets } from '../../src/core/block_asset_loader/block_css_loader'

if (IB === undefined) IB = {}
IB.isZoomActivated = false
const $el_base = $("style[data-base-styles='true']")

const accessibility_mode = IB.a11y.isAdaActivated()

// Al entrar en la web, compruebo si tengo activado el zoom en el navegador de tal manera que se comporte como un móvil pero realmente estamos en un dispositivo que no es un móvil
// Seteamos la variable de zoom activado
if (IB.currentDevice !== 'mobile' && MQBE.current_state() === 'mobile') {
  IB.isZoomActivated = true
}

$(document).on('enter.mobile.mqbe', function () {
  if (IB.currentDevice !== 'mobile' && MQBE.current_state() === 'mobile') {
    // Añado el css base mobile a partir de la URL que nos da el data-attribute almacenado en el html
    if (accessibility_mode !== true && !IB.is_in_microsite) {
      $(`<link rel='stylesheet' media='screen' href='${$el_base.attr('data-base-mobile')}' />`).appendTo('html')
    } else {
      $(`<link rel='stylesheet' media='screen' href='${$el_base.attr('data-base-mobile-accesible')}' />`).appendTo(
        'html'
      )
    }
    // Llamamos a la carga de assets de los bloques simulando que es un móvil para que cargue los assets de dicho dispositivo
    loadAllBlocksAssets(true)
  }
})

$(document).on('leave.mobile.mqbe', function () {
  // Elimino el css base mobile porque ahora no es necesario
  if (IB.currentDevice !== 'mobile' && (MQBE.current_state() === 'tablet' || MQBE.current_state() === 'desktop')) {
    $("link[href*='base_mobile']").remove()
  }
})
