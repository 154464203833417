// Script to control when a user scrolls

sessionStorage.setItem('userHasScrolled', 'false');

//Scrolling by keyboard
window.addEventListener('keydown', function (e) {
  if (sessionStorage.getItem('userHasScrolled') && sessionStorage.getItem('userHasScrolled') === 'false') {
    if (e.key == 33 // page up
      || e.key == 34 // page dn
      || e.key == 32 // spacebar
      || e.key == 38 // up
      || e.key == 40 // down
      || (e.ctrlKey && e.key == 36) // ctrl + home
      || (e.ctrlKey && e.key == 35) // ctrl + end
    ) {
      sessionStorage.setItem('userHasScrolled', 'true');
    }
  }
});

//scrolling by mouse wheel
window.addEventListener('mousewheel', function() {
  if (sessionStorage.getItem('userHasScrolled') && sessionStorage.getItem('userHasScrolled') === 'false') {
    sessionStorage.setItem('userHasScrolled', 'true');
  }
});

//Scrolling by vertical scroll bar
window.addEventListener('mousedown', function (event) {
  if (sessionStorage.getItem('userHasScrolled') && sessionStorage.getItem('userHasScrolled') === 'false') {
    if (event.target === document.getElementsByTagName('html')[0] && event.clientX >= document.documentElement.offsetWidth) {
      sessionStorage.setItem('userHasScrolled', 'true');
    }
  }
});

//Mobile finger scroll
window.addEventListener('touchmove', function (event) {
  window.addEventListener('scroll', function (event) { 
    if (sessionStorage.getItem('userHasScrolled') && sessionStorage.getItem('userHasScrolled') === 'false') {
      sessionStorage.setItem('userHasScrolled', 'true');
    }
  }, {once: true});
});

IB.resetUserScrollListener = function() {
  sessionStorage.setItem('userHasScrolled', 'false');
}