import { sendUtagEvent } from '../../core/analytics/utag_events'
import { hasUserAcceptedCookies } from '../../utils/cookie_manager'
import on from '../../utils/events_delegation'

// EVENTS STORE
const EVENTS_STORE_ID = 'events_store'

function existHashOnArray(array, hash) {
  let found = -1
  const hashString = JSON.stringify(hash)

  array.some((element, i) => {
    if (JSON.stringify(element) === hashString) {
      found = i
      return true
    }
  })

  return found
}

function readEventsStore() {
  return JSON.parse(sessionStorage.getItem(EVENTS_STORE_ID))
}

function setEventsStore(events) {
  sessionStorage.setItem(EVENTS_STORE_ID, JSON.stringify(events))
}

function removeEventsStore() {
  sessionStorage.removeItem(EVENTS_STORE_ID)
}

function storeEvent(event) {
  let events = readEventsStore()
  if (events) {
    if (existHashOnArray(events, event) === -1) {
      events.push(event)
    }
  } else {
    events = []
    events.push(event)
  }

  setEventsStore(events)
}

function sendStoredEvents() {
  const events = readEventsStore()

  if (!events || !events.length) return

  // iterate over each element in the array
  for (var i = 0; i < events.length; i++) {
    sendUtagEvent({
      data: events[i],
    })
  }

  removeEventsStore()
}

export function initializeUtagEvents() {
  // Btn: Aceptar cookies y continuar / Aceptar todas las cookies
  on({
    eventName: 'click',
    selector: '.cookies-warning #agree-cookies, .consent_preferences #agree-cookies-in-preferences-modal',
    handler() {
      // If cookies are accepted by first time then send stored events.
      sendStoredEvents()

      // Add cookies acceptance event
      sendUtagEvent({
        data: {
          event_name: 'cookie_policy',
          event_cat: 'cookie policy',
          event_act: 'accept cookies',
          event_lbl: '',
          event_purpose: 'information',
          event_structure: 'popup',
        },
      })
    },
  })

  // Link: Configuración
  on({
    eventName: 'click',
    selector: '.cookies-warning #cookies-config',
    handler() {
      const eventData = {
        event_name: 'cookie_policy',
        event_cat: 'cookie policy',
        event_act: 'manage configuration',
        event_lbl: '',
        event_purpose: 'information',
        event_structure: 'popup',
      }

      if (hasUserAcceptedCookies()) {
        sendUtagEvent({
          data: eventData,
        })
      } else {
        storeEvent(eventData)
      }
    },
  })

  // Link: Política de cookies
  on({
    eventName: 'click',
    selector: '.cookies-warning #cookie-policy, .consent_preferences #cookie-policy-in',
    handler() {
      const eventData = {
        event_name: 'cookie_policy',
        event_cat: 'cookie policy',
        event_act: 'cookie detail',
        event_lbl: '',
        event_purpose: 'information',
        event_structure: 'popup',
      }

      if (hasUserAcceptedCookies()) {
        sendUtagEvent({
          data: eventData,
        })
      } else {
        storeEvent(eventData)
      }
    },
  })

  // Btn: Guardar configuración
  on({
    eventName: 'click',
    selector: '.consent_preferences #preferences_prompt_submit',
    handler() {
      const eventData = {
        event_name: 'cookie_policy',
        event_cat: 'cookie policy',
        event_act: 'apply cookies',
        event_lbl: 'ana-adv-per-man',
        event_purpose: 'information',
        event_structure: 'popup',
      }

      if (hasUserAcceptedCookies()) {
        sendUtagEvent({
          data: eventData,
        })
      } else {
        storeEvent(eventData)
      }
    },
  })
}
