import tippy, { hideAll } from 'tippy.js'

import { currentDevice } from '../../core/utils/currentDevice'
import {
  IHG_API_PARAM,
  IHG_FASTBOOKING_SEARCH_PARAM,
  IHG_FIRST_LOGGED_VISIT,
  IHG_TOOLTIP_ACCEPTED,
  IHG_WIZARD_SHOWN,
  initIhgCookie,
  logoutFromIhg,
  sendFbDataToIhg,
  sendIhgEndedEvent,
  userHasRedeemablePoints,
} from '../../core/utils/ihg'

import { initializeUtagEvents } from './ihg_tooltip.analytics'

export function setUserData(ihgData, lightboxSelectorIhg) {
  const userDataStr = decodeURIComponent(ihgData)
  const userData = JSON.parse(userDataStr)
  const userName = lightboxSelectorIhg.querySelector('.js-title-modal-user-ihg')
  const userSubtitle = lightboxSelectorIhg.querySelector('.js-subtitle-modal-user-ihg')
  const userLevel = lightboxSelectorIhg.querySelector('.js-subtitle-user-level')
  const circleLevel = lightboxSelectorIhg.querySelector('.js-circle-user-level')

  if (!userName.textContent.includes(`${userData.name}`)) {
    if (userData.name === null) {
      userName.textContent += ''
    } else if (userData.level_code === null) {
      userLevel.textContent += ''
    } else {
      userName.textContent += userData.name.replaceAll('+', ' ')
      userLevel.textContent = userLevel.dataset[userData.level_code.toLocaleLowerCase()]

      userSubtitle.classList.add(userData.level_code.toLowerCase())
      circleLevel.classList.add(userData.level_code.toLowerCase())
    }
  }
}

/**
 * Show IHG wizard only in mobile after closing initial modal
 */
export function initIhgWizard() {
  const wizardContainer = document.querySelector('.js-ihg-wizard')
  const wizardContainerBackground = document.querySelector('.js-ihg-wizard-background')
  const wizardContainerBackgroundContent = document.querySelector('.js-ihg-wizard-background-content')
  const buttonCloseModalIHG = document.querySelector('.js-modal-ihg-close')
  const buttonCloseModalIHGData = document.querySelector('[data-micromodal-close]')

  wizardContainer.classList.remove('is-hidden')
  wizardContainerBackground.classList.remove('is-hidden')
  const ihgWizardTooltip = tippy((buttonCloseModalIHG, buttonCloseModalIHGData), {
    content: wizardContainer,
    trigger: 'click',
    theme: 'light',
    arrow: true,
    allowHTML: true,
    interactive: true,
    appendTo: () => wizardContainerBackgroundContent,
    showOnCreate: true,
    onShow() {
      ScrollLock.on()

      sessionStorage.setItem(IHG_WIZARD_SHOWN, 'true')
    },
    onHide() {
      wizardContainerBackground.classList.add('is-hidden')
      ScrollLock.off()
      setTimeout(sendIhgEndedEvent, 500)
    },
  })

  ihgWizardTooltip.show()
  document.querySelector('.js-wizard-ihg-close').addEventListener('click', () => {
    ihgWizardTooltip.hide()
  })
}

/**
 * Stores the current URL's search parameters in local storage if the specified parameter is present in the search parameters.
 *
 * @param {string} param - The search parameter to check.
 * @returns {void}
 */
function getSearchIHG(param) {
  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has(param)) {
    const paramsToString = urlParams.toString()
    localStorage.setItem(IHG_FASTBOOKING_SEARCH_PARAM, paramsToString)
  }
}

function hideIhgTooltipWhenScrolling() {
  window.addEventListener('scroll', () => {
    hideAll()
  })
}

function initTooltip(ihgData) {
  const initModalButtons = document.querySelectorAll('.js-ihg-modal-button')

  initModalButtons.forEach(initModalButton => {
    if (ihgData !== undefined) {
      const buttonCloseModalIHG = document.querySelectorAll('.js-modal-ihg-close')
      // Use logged
      const tooltipContentIhg = document.querySelector('.js-tooltip-ihg-user-logged')
      tooltipContentIhg.classList.remove('is-hidden')
      setUserData(ihgData, tooltipContentIhg)

      const ihgTooltip = tippy(initModalButton, {
        content: tooltipContentIhg,
        trigger: 'click',
        theme: 'light',
        arrow: true,
        allowHTML: true,
        interactive: true,
        placement: 'bottom-end',
        onShow() {
          if (userHasRedeemablePoints(ihgData)) {
            tooltipContentIhg.querySelector('.js-redeem-points').classList.remove('is-hidden')
          }
          tooltipContentIhg.querySelector('.js-redeem-points-link').addEventListener('click', e => {
            e.preventDefault()
            sendFbDataToIhg()
          })
          if (sessionStorage.getItem(IHG_TOOLTIP_ACCEPTED) && !currentDevice.isMobile) {
            buttonCloseModalIHG.forEach(button => {
              button.classList.add('hidden')
            })
          }
          tooltipContentIhg.querySelector('.js-redeem-points-link').addEventListener('click', e => {
            e.preventDefault()
            sendFbDataToIhg()
          })
          if (sessionStorage.getItem(IHG_TOOLTIP_ACCEPTED) && !currentDevice.isMobile) {
            buttonCloseModalIHG.forEach(button => {
              button.classList.add('hidden')
            })
          }
          sessionStorage.setItem(IHG_FIRST_LOGGED_VISIT, 'true')

          const logoutButton = tooltipContentIhg.querySelector('.js-ihg-logout')
          logoutButton.addEventListener('click', () => {
            logoutFromIhg(logoutButton)
          })
        },
        onHide() {
          sessionStorage.setItem(IHG_FIRST_LOGGED_VISIT, 'true')
          setTimeout(sendIhgEndedEvent, 500)
        },
      })

      if (!sessionStorage.getItem(IHG_FIRST_LOGGED_VISIT)) {
        ihgTooltip.show()
      }

      buttonCloseModalIHG.forEach(button => {
        button.addEventListener('click', e => {
          e.preventDefault()
          button.classList.add('hidden')
          if (!currentDevice.isMobile) {
            sessionStorage.setItem(IHG_TOOLTIP_ACCEPTED, 'true')
          }
          ihgTooltip.hide()
        })
      })
    } else {
      // User not logged
      const tooltipContentIhg = document.querySelector('.js-tooltip-ihg-user-no-logged')
      tooltipContentIhg.classList.remove('is-hidden')

      tippy(initModalButton, {
        content: tooltipContentIhg,
        trigger: 'click',
        theme: 'light',
        arrow: true,
        allowHTML: true,
        interactive: true,
        maxWidth: '400px',
        onShow() {
          getSearchIHG(IHG_API_PARAM)
        },
        onHide() {
          setTimeout(sendIhgEndedEvent, 500)
        },
      })
    }
  })
  hideIhgTooltipWhenScrolling()
}

function initIhgTooltip() {
  if (!currentDevice.isMobile) {
    const buttoninitModal = document.querySelectorAll('.js-ihg-modal-button')
    if (buttoninitModal.length > 0) {
      initIhgCookie(initTooltip)
    }
  }
  initializeUtagEvents()
}

initIhgTooltip()
