import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import fastbooking from '../../shared/fastbooking/ibh_fastbooking';
dayjs.extend(customParseFormat);

window.dataLayerFunctions = new function () {
  this.booking_submit = function (current_element, data) {
    let adults = 0;
    let children = 0;
    let destination = $('#vo_booking_country_id').length > 0 ? ' c' + $('#vo_booking_country_id').val() : $('#vo_booking_place_to_go').val();
    try {
      let action = $(current_element).attr('action');
      let tracker = ga.getAll()[0];
      let linkerParam = tracker.get('linkerParam');
      let url_separator = (action.indexOf('?') >= 0 ? '&' : '?');
      $(current_element).attr('action', action + url_separator + linkerParam);
    } catch (err) {

    }
    if ($('#mobile-hotels-selector').length > 0 && destination.length == 0) {destination = $('#mobile-hotels-selector').val();}
    let format = $('html').data('date-format');
    let check_in = dayjs($('.check-in').val(), format);
    let check_out = dayjs($('.check-out').val(), format);
    $(current_element).find('div.adults .js-input-number').each(function (index) {adults += parseInt($(this).val());});
    $(current_element).find('div.children .js-input-number').each(function (index) {children += parseInt($(this).val());});
    let num_rooms = $(current_element).find('.room-row').length;
    let diffDays = check_out.diff(check_in, 'days');
    let fastbooking_name = $(current_element).data('gtm-submit').eventAction;
    fastbooking_name = $('body').data('ea-fastbooking');
    if (data.eventCategory.indexOf('Ficha destino') >= 0) {fastbooking_name = data.eventAction;}
    check_in = check_in.isValid() ? check_in.format('DD/MM/YYYY') + ' entrada' : '';
    check_out = check_out.isValid() ? check_out.format('DD/MM/YYYY') + ' salida' : '';
    let event_action = $('#floating-fastbooking').css('position') == 'fixed' ? 'Sticky header' : fastbooking_name;
    let value = $.grep((current_element.getAttribute('id') == 'new_vo_booking' ? [destination, diffDays + ' noches', adults + ' adultos', children + ' niños', num_rooms + ' habitaciones', check_in, check_out] : [adults + ' adultos', children + ' niños', num_rooms + ' habitaciones', check_in, check_out]), Boolean).join(' | ');
    data.eventLabel = data.eventLabel + ' ' + value;
    data.eventAction = event_action;
    return data;
  };
  this.rooms_popover_confirm = function (current_element, data) {
    let adults = 0;
    let children = 0;
    let destination = $('#vo_booking_country_id').length > 0 ? ' c' + $('#vo_booking_country_id').val() : $('#vo_booking_place_to_go').val();
    let format = $('html').data('date-format');
    let check_in = dayjs($('.check-in').val(), format);
    let check_out = dayjs($('.check-out').val(), format);
    $('div.adults .js-input-number').each(function (index) {adults += parseInt($(this).val());});
    $('div.children .js-input-number').each(function (index) {children += parseInt($(this).val());});
    let num_rooms = $('div.adults .js-input-number').length;
    let diffDays = check_out.diff(check_in, 'days');
    let fastbooking_name = $(current_element).data('gtm-click').eventAction;
    fastbooking_name = $('body').data('ea-fastbooking');
    if (data.eventCategory.indexOf('Ficha destino') >= 0) {fastbooking_name = data.eventAction;}
    let event_action = fastbooking.isSticky ? 'Sticky header' : fastbooking_name;
    let value = (current_element.getAttribute('id') == 'new_vo_booking' ? [destination, diffDays + ' noches', adults + ' adultos', children + ' niños', num_rooms + ' habitaciones'] : [adults + ' adultos', children + ' niños', num_rooms + ' habitaciones']).join(' | ');
    data.eventLabel = data.eventLabel + ' ' + value;
    data.eventAction = event_action;
    return data;
  };
  this.init = function () {
    $('.chosen-search input[type=text]').keyup(function () {
      let $field = $(this);
      setTimeout(function () {
        // this is the value after the keypress
        let user_input = $field.val();
        let form = $field.parents('form');
        form.attr('data-user-input', user_input);
      }, 0);
    });
    this.hide_table();
  };

  this.submit_form = function (current_element, data) {
    switch (current_element.getAttribute('id')) {
    case 'new_vo_booking':
      data = this.booking_submit(current_element, data);
    }
    return data;
  };
}();

/**
 * Changes a given attribute value in inline gtm event data
 *
 * @param {string} event - name of the data attribute
 * @param {string} attr - name of the attribute to change inside data
 * @param {string} newValue - new value set
 *
 * @example $('.js-button').changeInlineDataGtm('data-gtm-click', 'eventCategory', 'testCAT')
 */
$.fn.changeInlineDataGtm = function (event, attr, newValue) {
  let $el = $(this);
  let eventAttr = $el.attr(event);
  let gtmData;
  if (eventAttr) {
    gtmData = JSON.parse(eventAttr);
    gtmData[attr] = newValue;
    $el.attr(event, JSON.stringify(gtmData));
  }
};

/**
 * Returns a requested attribute value
 *
 * @param {string} event - name of the data attribute
 * @param {string} attr - name of the attribute which we want to get the value
 *
 * @returns {string} attr - value of the attribute
 *
 * @example $('.js-button').getInlineDataGtmAttr('data-gtm-click', 'eventCategory')
 */
$.fn.getInlineDataGtmAttr = function (event, attr) {
  let $el = $(this);
  let eventAttr = $el.attr(event);
  let gtmData = eventAttr ? JSON.parse(eventAttr) : eventAttr;
  return eventAttr ? gtmData[attr] : '';
};

export function renameEventCategory($element, event_category_name) {
  let data_attr_names = ['gtm-click', 'gtm-change', 'gtm-submit'];
  $('body').attr('data-ea-fastbooking', event_category_name);
}

function event_category() {
  if ($('body').data('gtm-page') == undefined) {return;}
  return [$('body').data('gtm-page').pageCategory, $('body').data('gtm-page').pageSubCategory].filter(function (el) {return el !== '';}).join(' | ');
}
