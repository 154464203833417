import { sendUtagEvent } from '../core/analytics/utag_events'

import { ADA_DISABLED_EVENT, ADA_ENABLED_EVENT } from './constants'

const ADA_MARKETS = ['USA'] // markets with accessible mode enabled by default
const IS_ADA_MARKET = ADA_MARKETS.includes(IB.segmentation.market)
const ADA_CSS_CLASS = 'ada'
const ADA_BUTTON_CLASS = '.accessibility-button'
const ADA_LOCAL_STORAGE_KEY = 'accessibility_enabled'
const ADA_STATE_CHANGE_EVENT = {
  event_name: 'accessibility',
  event_cat: 'content accessibility',
  event_act: 'active accessibility',
  event_purpose: 'information',
  event_structure: 'header',
}

function isAdaActivated() {
  return JSON.parse(localStorage.getItem(ADA_LOCAL_STORAGE_KEY))
}

function activateAda() {
  localStorage.setItem(ADA_LOCAL_STORAGE_KEY, true)
  const ADA_EVENT = new Event(ADA_ENABLED_EVENT)
  document.dispatchEvent(ADA_EVENT)
  typeof utag !== 'undefined' && sendUtagEvent({ data: Object.assign(ADA_STATE_CHANGE_EVENT, { event_lbl: 'on' }) })
}

function deactivateAda() {
  localStorage.setItem(ADA_LOCAL_STORAGE_KEY, false)
  const ADA_EVENT = new Event(ADA_DISABLED_EVENT)
  document.dispatchEvent(ADA_EVENT)
  typeof utag !== 'undefined' && sendUtagEvent({ data: Object.assign(ADA_STATE_CHANGE_EVENT, { event_lbl: 'off' }) })
}

function toggleAdaStyles(isA11yEnabled) {
  const accessibility_check_button = document.querySelector(ADA_BUTTON_CLASS)

  if (!accessibility_check_button) return

  const a11yBtnYes = accessibility_check_button.querySelector('.a11y-yes')
  const a11yBtnNo = accessibility_check_button.querySelector('.a11y-no')
  const bodyTag = document.getElementsByTagName('body')[0]

  if (isA11yEnabled) {
    // activates CSS variables for ADA
    bodyTag.classList.add(ADA_CSS_CLASS)

    a11yBtnYes.classList.remove('hidden')
    a11yBtnNo.classList.add('hidden')
  } else {
    // replace CSS variables for ADA with standard ones
    bodyTag.classList.remove(ADA_CSS_CLASS)

    a11yBtnYes.classList.add('hidden')
    a11yBtnNo.classList.remove('hidden')
  }
}

function setADAinitialValueForLocalStorage() {
  let a11y_mode = localStorage.getItem(ADA_LOCAL_STORAGE_KEY)

  if (a11y_mode === null) {
    const initial_value = IS_ADA_MARKET
    localStorage.setItem(ADA_LOCAL_STORAGE_KEY, initial_value)
    a11y_mode = initial_value
  } else {
    // the value is not null
    // it then must contain a boolean value inside a String
    a11y_mode = JSON.parse(a11y_mode)
  }

  return a11y_mode
}

function onAdaButtonClick() {
  const isA11yEnabled = isAdaActivated()
  isA11yEnabled ? deactivateAda() : activateAda()
  toggleAdaStyles(!isA11yEnabled)
}

function initAda() {
  document.addEventListener('DOMContentLoaded', function () {
    const accessibility_mode = setADAinitialValueForLocalStorage()
    toggleAdaStyles(accessibility_mode)

    const accessibility_check_button = document.querySelector(ADA_BUTTON_CLASS)
    accessibility_check_button?.addEventListener('click', onAdaButtonClick)
  })
}

initAda()

IB.a11y = {
  onAdaButtonClick,
  isAdaActivated,
}
