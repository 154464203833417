import scrollTo from './scrollTo'

const body = document.getElementsByTagName('body')[0]
let locked = false
let prevScroll = {}
let prevStyles = ''
const lockStyles = 'position: fixed; height: 100%; width: 100%;'

const cacheStyles = function () {
  prevStyles = body.getAttribute('style') || ''
  prevScroll = {
    scrollLeft: window.scrollX,
    scrollTop: window.scrollY,
  }
}

const isLock = () => locked

const lock = () => {
  if (!locked) {
    locked = true
    cacheStyles()
    let bodyStyles = lockStyles
    bodyStyles += `height: auto; top: -${prevScroll.scrollTop}px; left: -${prevScroll.scrollLeft}px;`
    body.setAttribute('style', bodyStyles)
    body.classList.add('scroll-lock-on')
  }
}

const unlock = callback => {
  if (locked) {
    locked = false
    body.setAttribute('style', prevStyles)
    body.classList.remove('scroll-lock-on')
    scrollTo({ xCoord: prevScroll.scrollLeft, yCoord: prevScroll.scrollTop })
  }
  callback && callback()
}

const calcOverflow = () => {
  const hh = body.clientHeight()
  const hw = body.clientWidth()
  const wh = window.innerHeight()
  const ww = window.innerWidth()

  if (wh >= hh && ww >= hw) {
    lockStyles['overflow'] = 'hidden'
  } else {
    lockStyles['overflow-x'] = ww >= hw ? 'hidden' : 'scroll'
    lockStyles['overflow-y'] = wh >= hh ? 'hidden' : 'scroll'
  }
}

window.ScrollLock = {
  on: lock,
  off: unlock,
  isLock,
  toggle() {
    locked ? unlock() : lock()
  },
  update: calcOverflow,
}
