import { loadComponentAssets } from '../../lazy_wrappers/lazy_assets_loader';
import { BLOCK_SELECTOR } from '../../utils/blocks_load_classes';

const blocks = document.querySelectorAll(BLOCK_SELECTOR);

export const loadAllBlocksAssets = (inZoom) => {
  let blocksLength = blocks.length;
  for (let i = 0; i < blocksLength; i++) {
    loadComponentAssets(blocks[i], inZoom);
  }
};
