/**
 * Lazy Block Fancybox
 *
 * Receives the ajax content
 * and loads the assets if content is webpack block
 *
 */
import { loadComponentAssets } from './lazy_assets_loader';
import { BLOCK_SELECTOR, PENDING_CLASS } from '../utils/blocks_load_classes';

$(document).ajaxStop(checkloadComponentStyles);

export function checkloadComponentStyles() {
  let blocks = document.querySelectorAll(`.fancybox-container.fancybox-is-open ${BLOCK_SELECTOR}.${PENDING_CLASS}, .js-lazy-ajax${BLOCK_SELECTOR}.${PENDING_CLASS}`);
  let blocksLength = blocks.length;
  for (let i = 0; i < blocksLength; ++i) {
    loadComponentAssets(blocks[i], IB.isZoomActivated);
  }
}
