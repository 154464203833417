import { loadComponentAssets } from '../../lazy_wrappers/lazy_assets_loader'
import {
  BLOCK_SELECTOR,
  LOADING_CLASS,
  PACKED_BLOCK_CLASS,
  PENDING_CLASS,
  PRIORITIZED_BLOCK_CLASS,
} from '../../utils/blocks_load_classes'

let blockObserver

function loadBlocks(entries) {
  if (entries.length) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        const isPackedBlock = entry.target.classList.contains(PACKED_BLOCK_CLASS)

        // prioritized blocks have internal stylesheet in HTML,
        // no need to manage loading/skeleton classes.
        // Hopefully we will be able to remove this control
        // after hotels info with filtering re-do in Vue
        // (it's the only prioritized block for now)
        if (!entry.target.classList.contains(PRIORITIZED_BLOCK_CLASS)) {
          if (!isPackedBlock) {
            entry.target.classList.add(LOADING_CLASS)
          }
          entry.target.classList.remove(PENDING_CLASS)
        }

        loadComponentAssets(entry.target, IB.isZoomActivated)
        blockObserver.unobserve(entry.target)
      }
    })
  } else {
    blockObserver.disconnect()
  }
}

const blocks = document.querySelectorAll(BLOCK_SELECTOR)
const options = {
  root: null,
  rootMargin: '300px 0px 0px 0px',
  threshold: 0,
}

blockObserver = new IntersectionObserver(loadBlocks, options)

const blocksLength = blocks.length
for (let i = 0; i < blocksLength; ++i) {
  blockObserver.observe(blocks[i])
}
