// Setup isScrolling variable
let isScrolling

//scrollIntoView as a promise
Element.prototype.scrollIntoViewPromise = function (options) {
  this.scrollIntoView(options)
  const parent = this
  return {
    then(x) {
      const intersectionObserver = new IntersectionObserver(entries => {
        const [entry] = entries
        if (entry.isIntersecting) {
          setTimeout(() => x(), 100)
        }
      })
      intersectionObserver.observe(parent)
    },
  }
}

/**
 * End scroll event
 *
 * Triggers when scroll is ending
 */

function dispatchScrollEnding() {
  window.clearTimeout(isScrolling)

  isScrolling = setTimeout(function () {
    const scrollEnding = new Event('scrollEnding')
    dispatchEvent(scrollEnding)
  }, 66)
}

/**
 * Scroll Element Into View
 *
 * Scrolls smoothly to node element passed as argument.
 * Position determines to which part of the element to
 * scroll to.
 *
 * https://developer.mozilla.org/es/docs/Web/API/Element/scrollIntoView
 *
 */

export function scrollElementIntoView({ nodeElement, position = 'start', checkScrollEnd = false }) {
  if (checkScrollEnd) {
    const scrollEnd = new Event('scrollEnd')
    nodeElement.scrollIntoViewPromise().then(() => {
      document.dispatchEvent(scrollEnd)
    })
  } else {
    nodeElement.scrollIntoView({ behavior: 'smooth', block: position })
  }
}

/**
 * Scrolls to an element on the page with a specified distance from the top.
 * @param {string} selector - The selector (class or data attribute) of the element to scroll to.
 * @param {number} distance - The distance to subtract from the top position of the element.
 */
export function scrollToElement(selector, distance = 0) {
  // Get the element using the provided selector
  const element = document.querySelector(selector)
  // If the element exists
  if (element) {
    // Calculate the position of the element relative to the top of the viewport
    const position = element.getBoundingClientRect().top + window.scrollY
    // Scroll to the element with the specified distance from the top
    window.scrollTo({
      top: position - distance,
      behavior: 'smooth',
    })
  }
}

/**
 * Scroll to Element With Header
 *
 * Scrolls to element decreasing header height to prevent the element
 * from positioning below it.
 *
 * https://developer.mozilla.org/es/docs/Web/API/Window/scrollTo
 *
 */
export function scrollToElementWithHeader(nodeElement, header) {
  const isMobile = IB.currentDevice === 'mobile'
  if (header === undefined) {
    if (isMobile) {
      header = document.querySelector('.internal-nav-cnt-nfh .menu-trigger-responsive')
    } else {
      header = document.querySelector('.header-sticky-hotel')
    }
  }

  const scrollOpts = {
    top: nodeElement.offsetTop - header.offsetHeight,
    left: window.pageXOffset,
    behavior: 'smooth',
  }

  // Menu needs to be activated when scroll is ending.
  window.addEventListener('scroll', dispatchScrollEnding, false)
  window.addEventListener('scrollEnding', () => {
    header.classList.add('sticky')
    window.removeEventListener('scroll', dispatchScrollEnding, false)
  })

  window.scroll(scrollOpts)
}
