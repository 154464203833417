import { currentDevice } from '../../core/utils/currentDevice'
import {
  checkIhgUserSession,
  hasUserLoggedIhg,
  IHG_FIRST_LOGGED_VISIT,
  IHG_FIRST_VISIT_FROM_IHG,
  IHG_WELCOME_MODAL_SHOWN,
  IHG_WIZARD_SHOWN,
  initIhgCookie,
  logoutFromIhg,
  sendFbDataToIhg,
  sendIhgEndedEvent,
  setHeaderButton,
  userFromIhg,
  userHasRedeemablePoints,
} from '../../core/utils/ihg'
import { hasUserAcceptedCookies } from '../../utils/cookie_manager'
import on from '../../utils/events_delegation'
import { initIhgWizard, setUserData } from '../ihg_tooltips/ihg_tooltip'
import Modal from '../modal/modal'

import { initializeUtagEvents } from './ihg_modal.analytics'

const BLOCK_SELECTOR = '.js-welcome-modal-ihg'

export function showWelcomeModalIHG(modalId, data) {
  Modal.show(modalId, {
    onShow: () => {
      ScrollLock.on()
      if (hasUserLoggedIhg()) {
        document.querySelector('.js-modal-bottom-ihg-postlogin').classList.remove('hidden')
        document.querySelector('.js-modal-bottom-ihg-prelogin').classList.add('hidden')
        if (data && userHasRedeemablePoints(data) === true) {
          document.querySelector('.js-redeem-points').classList.remove('hidden')
        }
        document.querySelector('.js-redeem-points-link').addEventListener('click', e => {
          e.preventDefault()
          sendFbDataToIhg()
        })
      } else {
        document.querySelector('.js-modal-bottom-ihg-prelogin').classList.remove('hidden')
        document.querySelector('.js-modal-bottom-ihg-postlogin').classList.add('hidden')
      }
    },
    onClose: modal => {
      ScrollLock.off()
      modal.classList.remove('is-open')
      sessionStorage.setItem(IHG_WELCOME_MODAL_SHOWN, 'true')

      // Set IHG FIRST LOGGED
      if (hasUserLoggedIhg()) {
        sessionStorage.setItem(IHG_FIRST_LOGGED_VISIT, 'true')
      }

      // Manage IHG Wizard
      if (
        currentDevice.isMobile &&
        !sessionStorage.getItem(IHG_WIZARD_SHOWN) &&
        hasUserLoggedIhg() &&
        !modal.classList.contains('is-open')
      ) {
        initIhgWizard()
      } else {
        setTimeout(sendIhgEndedEvent, 500)
      }
    },
  })
}

export function initWelcomeModalIhg(data, show = false) {
  checkIhgUserSession(data)

  setHeaderButton(data)

  const modalId = 'js-modal-bottom-ihg'
  const modalContainer = document.querySelector(`.${modalId}`)

  if (!modalContainer) {
    return
  }

  if (data !== undefined) {
    setUserData(data, modalContainer)
  }

  Modal.init({
    openTrigger: 'data-ihg-modal-button',
    awaitCloseAnimation: true,
  })

  if (
    currentDevice.isMobile &&
    !sessionStorage.getItem(IHG_WELCOME_MODAL_SHOWN) &&
    hasUserAcceptedCookies() &&
    userFromIhg()
  ) {
    showWelcomeModalIHG(modalId, data)
  }

  if (
    currentDevice.isMobile &&
    data !== undefined &&
    !sessionStorage.getItem(IHG_FIRST_VISIT_FROM_IHG) &&
    hasUserAcceptedCookies() &&
    userFromIhg()
  ) {
    showWelcomeModalIHG(modalId, data)
    sessionStorage.setItem(IHG_FIRST_VISIT_FROM_IHG, 'true')
  }

  if (show) {
    showWelcomeModalIHG(modalId, data)
  }

  const logoutButton = modalContainer.querySelector('.js-ihg-logout')
  logoutButton.addEventListener('click', () => {
    logoutFromIhg(logoutButton)
  })
}

export function initIhgModal() {
  const showRewards = document.body.dataset.showRewards === 'true'

  if (!showRewards) return

  initIhgCookie(initWelcomeModalIhg)

  initializeUtagEvents()

  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .js-modal-ihg-close`,
    handler() {
      Modal.close()
    },
  })
}

initIhgModal()
