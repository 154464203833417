// COOKIES' BANNER CONTROL
//
// The cookies banner is managed from Tealium.
// Both CMS and CRS just control its visibility.
//
// The functionality consists of:
//
//    - The banner itself: showed if cookies_consent if present (created from Tealium upon acceptance).
//    - The cookies' settings lightbox. Each option is a checkbox that matches a numeric category.
//
// Also CONSENTMGR cookie is created (from Tealium) upon acceptance.
//
// If the user accepts cookies from:
//    The banner: the value's format is like "ts:1582055487817%7Cconsent:true"
//    The settings lightbox: the value's format contains "Cc6:1", being "Cc6:0" if personalization option is unchecked.
//
// Therefore, the appropiate way is to check wether CONSENTMGR contains "Cc6:0". Will work from both acceptance cases.
//
import '../../utils/scroll_lock'

import { initWelcomeModalIhg } from '../../shared/ihg_modal/ihg_modal'
import { hasUserAcceptedCookies, readCookie } from '../../utils/cookie_manager'
import { currentDevice } from '../utils/currentDevice'
import { hasUserLoggedIhg, IHG_WELCOME_MODAL_SHOWN, userFromIhg } from '../utils/ihg'

import { initializeUtagEvents } from './cookies_banner.analytics'

const cookiesConsent = readCookie('cookies_consent'),
  lang = document.getElementsByTagName('html')[0].getAttribute('lang')

const attachCookiesConfigClickEvent = () => {
  const link = document.querySelector('[data-tealium-cookies-config]')

  link &&
    link.addEventListener('click', function (e) {
      e.preventDefault()
      if (utag !== undefined && utag.gdpr !== undefined) {
        utag.gdpr.showConsentPreferences(lang)
        if (IB.currentDevice === 'mobile') {
          ScrollLock.on()
        }
      }
    })
}

const listenUserAcceptance = () => {
  const acceptanceButton = document.querySelector('.cookies-warning .cookie-btn')
  acceptanceButton.addEventListener(
    'click',
    () => {
      if (
        currentDevice.isMobile &&
        typeof utag !== 'undefined' &&
        typeof utag.gdpr !== 'undefined' &&
        userFromIhg() &&
        !hasUserLoggedIhg() &&
        !sessionStorage.getItem(IHG_WELCOME_MODAL_SHOWN)
      ) {
        initWelcomeModalIhg(undefined, true)
      }
    },
    true
  )
}

const loadCookiesBanner = () => {
  const cookiesConsent = readCookie('cookies_consent')
  if (cookiesConsent === undefined && typeof utag !== 'undefined' && typeof utag.gdpr !== 'undefined') {
    utag.gdpr.showExplicitConsent(lang)
    listenUserAcceptance()
  }
}

const initTealiumCookies = () => {
  if (!hasUserAcceptedCookies()) {
    requestIdleCallback(loadCookiesBanner)
  }
  attachCookiesConfigClickEvent()
  initializeUtagEvents()
}

initTealiumCookies()
