import { getCurrentLocale } from '../../core/utils/locale.js'
import fastbooking from '../../shared/fastbooking/ibh_fastbooking.js'
import { deleteCookies, readCookie } from '../../utils/cookie_manager'
import { getParam } from '../../vendors/urlParser/urlParser'

import { currentDevice } from './currentDevice'

// URL param to know we come from IHG
export const IHG_API_PARAM = 'ihg_api'
// URL param where fastbooking params come from IHG
export const IHG_FASTBOOKING_SEARCH_PARAM = 'ihg_fastbooking_search'
// Cookie to store IHG data
export const IHG_CMS_COOKIE = 'ihg_data'
// Cookie to store IHG user info data
export const IHG_SHARED_COOKIE = 'IHG_INFO'
// Session storage key to store the user id
export const IHG_USER_ID = 'ihg_user_id'
// Session storage key to store if the welcome modal has been shown
export const IHG_WELCOME_MODAL_SHOWN = 'ihg_welcome_modal_shown'
// Session storage key to store if the user has come from IHG for the first time
export const IHG_FIRST_VISIT_FROM_IHG = 'ihg_first_visit_from_ihg'
// Session storate key to store if it is the first time the user visits the site after login in IHG
export const IHG_FIRST_LOGGED_VISIT = 'ihg_first_logged_visit'
// Session storage key to store if the user has accepted the tooltip
export const IHG_TOOLTIP_ACCEPTED = 'ihg_tootlip_accepted'
// Session storage key to store if the mobile wizard has been shown
export const IHG_WIZARD_SHOWN = 'ihg_wizard_shown'
// Name of the event to trigger when the IHG modals / tooltips has been shown
export const IHG_ENDED_EVENT_NAME = 'ihg_ended_event'

const neededPoints = 10000

/**
 * Check if user is logged in IHG through the cookie
 */
export const hasUserLoggedIhg = () => {
  return readCookie(IHG_CMS_COOKIE) !== undefined
}

/**
 * Check if user comes from IHG through the URL param
 */
export const userFromIhg = () => {
  return getParam(IHG_API_PARAM) !== undefined
}

export function initIhgCookie(callback) {
  const ihgCmsData = readCookie(IHG_CMS_COOKIE)
  const ihgSharedData = readCookie(IHG_SHARED_COOKIE)

  if (ihgSharedData !== undefined && ihgCmsData === undefined) {
    // Regenerate the CMS cookie
    fetch('/api/ihg/sessions/ihg_cookie', {
      method: 'POST',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        return response.json()
      })
      .then(result => {
        if (result) {
          const data = result.cookies.ihg_data.value
          callback(data)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  } else if (ihgSharedData === undefined && ihgCmsData !== undefined) {
    deleteCookies([IHG_CMS_COOKIE])
  } else {
    callback(ihgCmsData)
  }
}

export function logoutFromIhg(button) {
  button.classList.add('hidden')
  fetch('/api/ihg/sessions/ihg_logout', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then(response => {
      if (response.status === 200 || response.status === 401) {
        window.location.reload()
      } else {
        button.classList.remove('hidden')
      }
    })
    .catch(error => {
      button.classList.remove('hidden')
      console.error('Error:', error)
    })
}

export const userHasRedeemablePoints = ihgData => {
  const userDataStr = decodeURIComponent(ihgData)
  const userData = JSON.parse(userDataStr)

  // Points needed to redeem

  const pointsToRedeem = userData.user_points
  return pointsToRedeem >= neededPoints
}

/**
 * Send event when IHG modals / tooltips has been shown
 */
export function sendIhgEndedEvent() {
  const event = new CustomEvent(IHG_ENDED_EVENT_NAME)
  document.dispatchEvent(event)
}

export function sendFbDataToIhg() {
  const fastbookingState = fastbooking.state
  const roomsInfo = fastbookingState.rooms_info

  const result = JSON.stringify({
    localCode: getCurrentLocale(),
    id_and_type: fastbookingState.place_to_go?.id_and_type || '',
    check_in_date: fastbookingState.dates ? fastbookingState.dates.check_in : '',
    check_out_date: fastbookingState.dates ? fastbookingState.dates.check_out : '',
    number_of_rooms: Object.keys(roomsInfo).length,
    number_of_adults: fastbookingState.people.adults,
    number_of_children: fastbookingState.people.children,
    /* If is hotel page or destination page we send a basic link with the needed parameters to IHG */
    sendBaseLink: !(IB.currentPageType.isHotel() || IB.currentPageType.isDestination()),
  })

  fetch('/api/ihg/sessions/ihg_redirect', {
    method: 'POST',
    body: result,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then(response => response.json())
    .then(urlToRedirect => {
      window.location.href = urlToRedirect
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function checkIhgUserSession(data) {
  if (data !== undefined) {
    const userDataStr = decodeURIComponent(data)
    const userData = JSON.parse(userDataStr)
    const userDataId = userData.id

    const userDataStorage = parseInt(sessionStorage.getItem(IHG_USER_ID), 10)

    if (userDataStorage !== userDataId) {
      sessionStorage.removeItem(IHG_FIRST_LOGGED_VISIT)
      sessionStorage.removeItem(IHG_WELCOME_MODAL_SHOWN)
      sessionStorage.removeItem(IHG_FIRST_VISIT_FROM_IHG)
      sessionStorage.removeItem(IHG_TOOLTIP_ACCEPTED)
      sessionStorage.removeItem(IHG_WIZARD_SHOWN)
    }
  }
}

export function setHeaderButton(data) {
  /**
   * We get all the buttons that have the class js-ihg-modal-button cause we have
   * both headers at first, and when we check the cookie we remove one of them
   */
  const headerIhgButtons = document.querySelectorAll('.js-ihg-modal-button')

  headerIhgButtons.forEach(button => {
    const buttonBeforeLogin = button.querySelector('.js-ihg-button-before-login')
    const buttonAfterLogin = button.querySelector('.js-ihg-button-after-login')

    if (data !== undefined) {
      const userDataStr = decodeURIComponent(data)
      const userData = JSON.parse(userDataStr)
      const userDataId = userData.id

      sessionStorage.setItem(IHG_USER_ID, userDataId)

      buttonBeforeLogin.classList.add('hidden')
      buttonAfterLogin.classList.remove('hidden')
      button.classList.add('logged')
      buttonAfterLogin.textContent = buttonAfterLogin.dataset[userData.level_code.toLocaleLowerCase()]
    } else {
      buttonBeforeLogin.classList.remove('hidden')
      buttonAfterLogin.classList.add('hidden')
      button.classList.remove('logged')
    }
  })
}

/**
 * Updates the URL parameters of the current page with the parameters stored in local storage and redirects to the updated URL.
 * If stored parameters are found, they are compared with the current URL parameters.
 * Missing parameters are added to the current URL, and the updated URL is then reflected in the browser.
 * The stored parameters are removed from local storage, and the page is reloaded.
 *
 * @returns {void}
 */
function updateURLParamsAndRedirect() {
  const currentURL = new URL(window.location.href)

  // Retrieve parameters stored in LS
  const storedParams = localStorage.getItem(IHG_FASTBOOKING_SEARCH_PARAM)

  if (storedParams) {
    const storedParamsObj = new URLSearchParams(storedParams)

    // Compare the parameters of the current URL with the saved parameters
    storedParamsObj.forEach((value, key) => {
      if (!currentURL.searchParams.has(key)) {
        // Add missing parameters to the current URL
        currentURL.searchParams.set(key, value)
      }
    })

    // Update the URL in the browser
    window.history.replaceState({}, '', currentURL.href)

    // Delete variable from localStorage
    localStorage.removeItem(IHG_FASTBOOKING_SEARCH_PARAM)

    // Reload the page
    window.location.reload()
  }
}

/**
 * Check if we need to show IHG restrictions message
 * @returns {boolean}
 */
export function showIhgRestrictionsMessage() {
  const fbState = fastbooking.state
  if (!fbState) return false
  if (fbState.place_to_go) {
    const hotelId = fbState.place_to_go?.id_and_type
    const hotelData = IB.hotels_data.getHotelById(hotelId)
    if (!hotelData) return false

    const max_adults = hotelData.data.max_adults
    const max_children = hotelData.data.max_children

    const people = fbState.people

    return people.adults > max_adults || people.children > max_children
  }
  return false
}

/**
 * Open paxes panel when the IHG modal / tooltip has been shown, if needed
 */
function ihgEnded() {
  if (showIhgRestrictionsMessage()) {
    if (currentDevice.isMobile) {
      IB.fastbooking.openOccupancyPanel()
    } else {
      document.querySelector('.input.people .number-of-guest').click()
    }
  }
  document.removeEventListener(IHG_ENDED_EVENT_NAME, ihgEnded)
}

function initIhg() {
  const ihgCmsData = readCookie(IHG_CMS_COOKIE)
  const paramsUrlRedirectIhg = localStorage.getItem(IHG_FASTBOOKING_SEARCH_PARAM)

  // If the user is logged in and the parameter exists in LocalStorage
  if (ihgCmsData && paramsUrlRedirectIhg) {
    updateURLParamsAndRedirect()
  }

  document.addEventListener(IHG_ENDED_EVENT_NAME, ihgEnded)

  requestIdleCallback(() => {
    if (userFromIhg()) {
      if (
        (!hasUserLoggedIhg() && sessionStorage.getItem(IHG_WELCOME_MODAL_SHOWN)) ||
        (hasUserLoggedIhg() &&
          sessionStorage.getItem(IHG_FIRST_LOGGED_VISIT) &&
          sessionStorage.getItem(IHG_FIRST_VISIT_FROM_IHG) &&
          (sessionStorage.getItem(IHG_TOOLTIP_ACCEPTED) || sessionStorage.getItem(IHG_WIZARD_SHOWN)))
      ) {
        sendIhgEndedEvent()
      }
    }
  })
}

initIhg()
