import { loadLazyBlockByNode } from '../../lazy_wrappers/lazy_block_by_node_loader';
import { LAZY_BLOCK_SELECTOR } from '../../utils/blocks_load_classes';

const blocks = document.querySelectorAll(LAZY_BLOCK_SELECTOR);

let blocksLength = blocks.length;
let requestsSent = false;

window.addEventListener('scroll', () => {
  if (!requestsSent) {
    for (let i = 0; i < blocksLength; i++) {
      loadLazyBlockByNode(blocks[i]);
    }
  }

  requestsSent = true;
});
