/*
 * !
 * Media Query breakpoints events (MQBE) 2.0.3
 * Small JS lib that fires events when you enter / leave a CSS mediaquery.
 *
 * https://github.com/carloscabo/MQBE
 *
 * Copyright 2016, Carlos Cabo
 *
 * Licensed under MIT
 *
 * Released on: June 22, 2016
 */

import './mqbe.scss';

const $doc = $(document);
let current_state;

// If state changed sets the data vars and tries to launch the callback (if exists)
const check_state = () => {
  let previous_state = current_state;
  current_state = get_current_state();

  if (previous_state !== current_state) {
    $doc.trigger('mqbe');
    $doc.trigger('leave.' + previous_state + '.mqbe');
    $doc.trigger('enter.' + current_state + '.mqbe');
  }
  return current_state;
};

// Get state from body:after content
const get_current_state = () => {
  let state = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
  return state.replace(/["']/g, ''); // Firefox / Chrome 43 bugfix
};

// Has the browser mediaqueries support?
const detect_mq_support = () => typeof window.matchMedia !== 'undefined' || typeof window.msMatchMedia !== 'undefined';

/*
 * Debounced resize
 * https://github.com/louisremi/jquery-smartresize/
 */
const on_resize = (c, t) => {
  window.onresize = function () {
    clearTimeout(t);
    t = setTimeout(c, 250);
  };
  return c;
};

if (detect_mq_support()) {
  $(function () {
    check_state();

    // Start listener ASAP if mq are supported
    on_resize(function () {
      check_state();
    });
  });
}

window.MQBE = {
  supported:     detect_mq_support,
  current_state: get_current_state
};
