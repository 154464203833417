/**
 * Url parser
 */

/**
 * Get all params
 *
 * @return {object} Object containing current url params
 */
export function getParams() {
  const query = decodeURIComponent(location.search.substr(1))
  const result = {}
  query.split('&').forEach(function (part) {
    if (!part) return
    const param = part.split('+').join(' ') // replace every + with space, regexp-free version
    const eq = param.indexOf('=')
    let key = eq > -1 ? param.substr(0, eq) : param
    const val = eq > -1 ? decodeURIComponent(param.substr(eq + 1)) : ''
    const from = key.indexOf('[')
    if (from === -1) result[decodeURIComponent(key)] = val
    else {
      const to = key.indexOf(']', from)
      const index = decodeURIComponent(key.substring(from + 1, to))
      key = decodeURIComponent(key.substring(0, from))
      if (!result[key]) result[key] = []
      if (!index) result[key].push(val)
      else result[key][index] = val
    }
  })
  return result
}

/**
 * Get a specific param
 *
 * @param {string} param - name of the param to get his value
 * @return {object} - Object containing current url params
 */
export function getParam(param) {
  try {
    const params = getParams()
    return params[param]
  } catch (error) {
    return 'false'
  }
}

/**
 * Checks if a parameter is present in the URL.
 * @function
 * @param {string} param - The name of the parameter to check.
 * @returns {boolean} - Returns true if the parameter is present, otherwise false.
 */
export function hasParam(param) {
  const paramValue = getParam(param)
  return paramValue !== undefined && (paramValue === '' || paramValue.length)
}

window.IB.urlParser = {
  getParams,
  getParam,
}
