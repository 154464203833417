/*
 * deviceAndMqbeMatch
 *
 * Checks if detected device match the current media query.
 * The case where it does not match is mobile/tablet landscape,
 * which ends up at upper level of media query device:
 *  horizontal mobile => tablet media query
 *  horizontal tablet => desktop media query
 *
 * See also enter.mobile.mqbe and enter.tablet.mqbe events in ready.js
 *
 * @returns boolean
 */

export function deviceAndMqbeMatch() {
  const htmlTag = document.getElementsByTagName('html')[0],
    deviceType = htmlTag.dataset.deviceType,
    current_mqbe = IB.currentDevice

  return current_mqbe === deviceType
}
