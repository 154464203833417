import { sendUtagEvent } from '../../../src/core/analytics/utag_events'
import on from '../../utils/events_delegation'

const NOT_LOGGED_BLOCK_SELECTOR = '.js-tooltip-ihg-user-no-logged'
const LOGGED_BLOCK_SELECTOR = '.js-tooltip-ihg-user-logged'

export function initializeUtagEvents() {
  /* Click on loigin cta of the tooltip */
  on({
    eventName: 'click',
    selector: `${NOT_LOGGED_BLOCK_SELECTOR} .utag-ihg-login-in-tooltip`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'click sign up',
          event_lbl: 'popup',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })
  /* once logged in ihg, click on accept cta of the tooltip */
  on({
    eventName: 'click',
    selector: `${LOGGED_BLOCK_SELECTOR} .utag-ihg-accept`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'accept special member',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the one reward area link */
  on({
    eventName: 'click',
    selector: `${LOGGED_BLOCK_SELECTOR} .utag-private-area`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'one rewards area',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the one reward space link to redeem points */
  on({
    eventName: 'click',
    selector: `${LOGGED_BLOCK_SELECTOR} .utag-redeem-points`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'hg one rewards space',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the FAQ link */
  on({
    eventName: 'click',
    selector: `${LOGGED_BLOCK_SELECTOR} .utag-ihg-faq`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'faqs',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the close(log out) link */
  on({
    eventName: 'click',
    selector: `${LOGGED_BLOCK_SELECTOR} .utag-ihg-logout`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'close login',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })
}
