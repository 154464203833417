/**
 * Lazy Block by (skeleton) Node Loader
 *
 * Receives the skeleton's html node for a lazy block
 * and replaces it with the block's html.
 *
 *
 * Requirements:
 *
 *    - The page must be a constrained view.
 *
 *    - The node must have a data-block-id attribute
 *      with a block ID.
 */
import { getLazyData } from '../utils/get_lazy_data';
import { loadComponentAssets } from './lazy_assets_loader';
import { BLOCK_SELECTOR, LOADING_CLASS, PACKED_BLOCK_CLASS } from '../utils/blocks_load_classes';
import { scrollToElementWithHeader } from '../utils/scroll_to_element';

let hash = window.location.hash.toLowerCase();

export function loadLazyBlockByNode(skeletonNode) {
  const CONSTRAINED_VIEW_ID = document.getElementsByTagName('body')[0].dataset.constrainedViewId;
  const BLOCK_ID = skeletonNode.dataset.blockId;

  if (CONSTRAINED_VIEW_ID && BLOCK_ID) {
    let lang = document.getElementsByTagName('html')[0].lang;
    lang = lang === 'es' ? '' : '/' + lang;
    const MARKET_ID = document.getElementsByTagName('html')[0].dataset.marketId;
    const USER_LEVEL = document.getElementsByTagName('body')[0].dataset.constrainedViewLevel;
    const device = document.getElementsByTagName('html')[0].dataset.deviceType;
    const URL = `${lang}/load_blocks/?block_id=${BLOCK_ID}&constrained_view_id=${CONSTRAINED_VIEW_ID}&market_id=${MARKET_ID}&deviceType=${device}&user_level=${USER_LEVEL}`;

    getLazyData(URL, function (stringHtml) {
      let block = replaceSkeletonWithHTML(skeletonNode, stringHtml);
      let is_prioritized = false;
      if (block != null) {
        if (document.querySelector('[data-page-type="hotel"]') && blockInHash(block)) {
          window.addEventListener('load', function () {
            if (sessionStorage.getItem('userHasScrolled') === 'false') scrollToElementWithHeader(block);
            sessionStorage.removeItem('userHasScrolled');
          });
        }
        /* Checks if the lazy loaded block is a prioritized block
         * to call init function on it.
         *
         * Otherwise, load the lazy CSS and JS.
         */
        if (window[`${block.dataset.blockLayout}_init`]) {
          is_prioritized = true;
          window[`${block.dataset.blockLayout}_init`]();
          block.classList.remove(LOADING_CLASS);
        }

        if (!is_prioritized) {
          loadComponentAssets(block);
        }
      }
    });
  }
}

/**
 * replaceSkeletonWithHtml()
 *  skeletonNode: dom node
 *  stringHtml: string returned in XHR petition
 *
 * Replace the skeleton with the html returned
 * from a server fetch.
 */
function replaceSkeletonWithHTML(skeletonNode, stringHtml) {
  const PARSED_HTML = document.createRange().createContextualFragment(stringHtml.trim());
  const BLOCK = PARSED_HTML.querySelector(BLOCK_SELECTOR);
  IB.lazyImg.addImgToObserver($(PARSED_HTML));
  if (BLOCK !== null) {
    BLOCK.classList.add(LOADING_CLASS);
    skeletonNode.replaceWith(PARSED_HTML);
    return BLOCK;
  } else {
    return null;
  }
}

function blockInHash(block) {
  return (
    hash !== '' &&
    ((block.attributes.id != null && block.attributes.id.value === hash.slice(1)) || (block.attributes.name != null && block.attributes.name.value === hash.slice(1)))
  );
}
