import { deviceAndMqbeMatch } from '../core/iberapi/mqbe_device_type_match/mqbe_device_type_match'
import { currentDevice } from '../core/utils/currentDevice'
import { LOADING_CLASS, PACKED_BLOCK_CLASS, PENDING_CLASS } from '../utils/blocks_load_classes'

const htmlTag = document.getElementsByTagName('html')[0],
  deviceType = htmlTag.dataset.deviceType,
  isAdminBrowsing = document.querySelectorAll('.admin-browsing').length

const loadingPromises = {}

const getBlockName = blockNode => {
  return blockNode.dataset.blockLayout
}

const getBlockFolderName = blockNode => {
  const IS_PACKED_BLOCK = blockNode.classList.contains(PACKED_BLOCK_CLASS)
  return IS_PACKED_BLOCK ? `${getBlockName(blockNode)}_packed` : getBlockName(blockNode)
}

const getBlockId = blockNode => {
  return blockNode.dataset.blockId
}

const removeLoadingClass = blockName => {
  document.querySelectorAll(`[data-block-layout="${blockName}"]`).forEach(function (el) {
    el.classList.remove(LOADING_CLASS)
    el.classList.remove(PENDING_CLASS)
  })
  if (isAdminBrowsing) {
    IB.adminBrowsing.adminBrowsingBtnPosition()
  }
}

export const buildCSSFileName = function (blockName, inZoom) {
  // check user's device type and current media query to load all styles or mobile or small tablets.
  const cssName =
    (deviceType === 'mobile' && deviceAndMqbeMatch()) || currentDevice.isLikeMobile || inZoom
      ? `${blockName}_mobile`
      : blockName

  return cssName
}

export async function loadComponentAssets(blockNode, inZoom) {
  const BLOCK_NAME = getBlockName(blockNode)
  const BLOCK_ID = getBlockId(blockNode)
  if (loadingPromises[BLOCK_NAME]) {
    if (loadingPromises[BLOCK_NAME].cssLoaded && loadingPromises[BLOCK_NAME].jsLoaded) {
      removeLoadingClass(BLOCK_NAME)
      if (!blockNode.classList.contains('has-js-initialized') && loadingPromises[BLOCK_NAME].init) {
        loadingPromises[BLOCK_NAME].init(BLOCK_ID, BLOCK_NAME)
        blockNode.classList.add('has-js-initialized')
      }
      return Promise.resolve()
    }

    loadingPromises[BLOCK_NAME].jsPromise.then(() => {
      if (!blockNode.classList.contains('has-js-initialized') && loadingPromises[BLOCK_NAME].init) {
        loadingPromises[BLOCK_NAME].init(BLOCK_ID, BLOCK_NAME)
        blockNode.classList.add('has-js-initialized')
      }
    })

    try {
      return await Promise.all([loadingPromises[BLOCK_NAME].cssPromise, loadingPromises[BLOCK_NAME].jsPromise])
    } catch (error) {
      // console.warn(error);
    }
  }

  const IS_PACKED_BLOCK = blockNode.classList.contains(PACKED_BLOCK_CLASS),
    BLOCK_FOLDER_NAME = getBlockFolderName(blockNode, IS_PACKED_BLOCK),
    CSS_NAME = buildCSSFileName(BLOCK_NAME, inZoom)

  loadingPromises[BLOCK_NAME] = {
    cssPromise: null,
    cssLoaded: IS_PACKED_BLOCK,
    jsPromise: null,
    jsLoaded: false,
    init: null,
  }

  let cssPromise = null

  if (!IS_PACKED_BLOCK) {
    cssPromise = import(
      /* webpackChunkName: '[request]' */
      /* webpackExclude: /commons|packed/ */
      `../blocks/${BLOCK_NAME}/${CSS_NAME}.scss`
    )

    loadingPromises[BLOCK_NAME].cssPromise = cssPromise

    cssPromise
      .then(() => {
        removeLoadingClass(BLOCK_NAME)
        loadingPromises[BLOCK_NAME].cssLoaded = true
      })
      .catch(error => {
        // console.warn(error)
      })
  }

  const jsPromise = import(
    /* webpackChunkName: '[request]' */
    `../blocks/${BLOCK_FOLDER_NAME}/${BLOCK_NAME}.js`
  )

  loadingPromises[BLOCK_NAME].jsPromise = jsPromise

  jsPromise
    .then(blockJS => {
      if (blockJS && blockJS.init) {
        blockJS.init(BLOCK_ID, BLOCK_NAME)
        loadingPromises[BLOCK_NAME].init = blockJS.init
        blockNode.classList.add('has-js-initialized')
      }
      loadingPromises[BLOCK_NAME].jsLoaded = true
    })
    .catch(error => {
      // console.warn(error)
    })

  removeLoadingClass(BLOCK_NAME)

  try {
    return await Promise.all([cssPromise, jsPromise])
  } catch (error_3) {
    //console.warn(error)
  }
}
