
/**
 * Native scrollTo with callback
 * @param xCoord - X coord to scroll to
 * @param yCoord - Y coord to scroll to
 * @param callback - callback function
 */
const scrollTo = ({ xCoord = 0, yCoord = 0, callback = () => {} }) => {
  const fixedOffset = yCoord.toFixed();
  const onScroll = function () {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback && callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo(xCoord, yCoord);
};

export default scrollTo;
