import { sendUtagEvent } from '../../../src/core/analytics/utag_events'
import on from '../../utils/events_delegation'

const BLOCK_SELECTOR = '.js-welcome-modal-ihg'

export function initializeUtagEvents() {
  /* Event off user logged when they come back logged*/
  window.addEventListener('load', () => {
    if (document.querySelector('.utag-ihg-modal-button')?.classList.contains('logged')) {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'user logged',
          event_lbl: 'impression',
          event_purpose: 'loyalty',
          event_structure: 'impression',
        },
      })
    }
  })

  /* Click on login cta of the modal  */
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-ihg-login-in`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'click sign up',
          event_lbl: 'header',
          event_purpose: 'loyalty',
          event_structure: 'header',
        },
      })
    },
  })

  /* once logged in ihg, click on accept cta of the modal mobile */
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-ihg-accept`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'accept special member',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the one reward area link */
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-private-area`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'one rewards area',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the one reward space link to redeem points */
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-redeem-points`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'hg one rewards space',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the FAQ link */
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-ihg-faq`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'faqs',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })

  /*  once logged in ihg, click on the close(log out) link */
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-ihg-logout`,
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'ihg_one_rewards',
          event_cat: 'ihg one rewards',
          event_act: 'close login',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'popup',
        },
      })
    },
  })
}
