/**
 * File to apply mobile styles on tablets with a width less than 768px
 *
 * */

import { currentDevice } from '../utils/currentDevice'

function hostName() {
  const protocol = window.location.protocol
  const hostName = window.location.hostname
  const port = window.location.port

  if (port) {
    return `${protocol}//${hostName}:${port}`
  }
  return `${protocol}//${hostName}`
}

/**
 * Returns the sub string of words from the word that has been appended to it.
 *
 * @param {string} chain
 * @param {string} word
 * @returns
 */
function deleteBeforeWord(chain, word) {
  const newChain = chain.substring(chain.indexOf(word))
  return newChain
}

/**
 * Create new link element
 *
 * @param {string} url
 */
function createNewLinkCss(url) {
  const linkCustomize = document.createElement('link')
  linkCustomize.href = url
  linkCustomize.rel = 'stylesheet'
  linkCustomize.setAttribute('data-modified', true)
  document.head.appendChild(linkCustomize)
}

document.addEventListener('DOMContentLoaded', function () {
  const base = document.querySelector('style[data-base-styles="true"]')
  const dataInUse = base?.dataset.inUse

  if (currentDevice.isLikeMobile && !dataInUse.includes('mobile')) {
    // Fold
    // Ej: //iberostar-web.test:3000/assets/packs/css/fold/base_mobile-f0c766ec.css
    const dataBaseMobile = base.dataset.baseMobile
    // Ej: http://iberostar-web.test:3000/assets/packs/css/fold/base_mobile-f0c766ec.css
    const urlDataBaseMobile = `${hostName()}/${deleteBeforeWord(dataBaseMobile, 'assets')}`
    createNewLinkCss(urlDataBaseMobile)

    // Header customized
    const dynamicCssHeaders = Array.from(document.querySelectorAll('.js-dinamic-header-css'))
    dynamicCssHeaders.forEach(header => {
      const dataBlockBaseMobile = header.dataset.blockMobileStyles
      const urlCssHeaders = `${hostName()}/${deleteBeforeWord(dataBlockBaseMobile, 'assets')}`

      createNewLinkCss(urlCssHeaders)

      // Delete the original in case of conflict
      header.remove()
    })

    // Main css
    const mainCss = document.querySelector('#pack-css')
    const mainUrlCssMobile = mainCss.dataset.baseMobile
    mainCss.setAttribute('href', mainUrlCssMobile)
  }
})
