import dayjs from 'dayjs';
import { setCookies } from '../../utils/cookie_manager';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

dayjs.extend(duration);

function isNewSession() {
  let time = $.cookie('last_access') == undefined ? dayjs() : $.cookie('last_access');
  let now = dayjs();
  return dayjs.duration(now.diff(time)).asMinutes() > 1;
}
function init() {
  let count = $.cookie('visitor_session_count') == undefined ? 0 : $.cookie('visitor_session_count');
  let lastActive = dayjs();
  let base_domain = '.' + document.domain.split('.').slice(-2).join('.');

  if (count === 0 || isNewSession()) {
    count = parseInt(count) + 1;
  }
  setCookies([
    {'name': 'last_access', 'value': lastActive, 'domain': base_domain},
    {'name': 'visitor_session_count', 'value': count, 'domain': base_domain}
  ]);
}

window.IB.utag_session_counter = {
  init: init
};

IB.utag_session_counter.init();
